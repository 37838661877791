/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */

import moment from 'moment';

type WeekYear = {
  week: number;
  year: number;
};

export const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};

// export const getISOWeekDate: any = (
//   w: number,
//   y: number = new Date().getFullYear()
// ) => {
//   if (w < 1 || w > 53) throw new Error('Week number must be between 1 and 53');

//   const firstDay = new Date(Date.UTC(y, 0, 1));
//   const firstThursday = new Date(
//     Date.UTC(y, 0, 1 + ((4 - firstDay.getUTCDay() + 7) % 7))
//   );

//   if (w === 1) {
//     const jan1Day = firstDay.getUTCDay();
//     if (jan1Day > 4) return getISOWeekDate(w, y + 1);
//   }

//   const targetDate = new Date(firstThursday);
//   targetDate.setUTCDate(firstThursday.getUTCDate() + (w - 1) * 7);

//   const targetYear = targetDate.getUTCFullYear();
//   if (targetYear > y) {
//     const nextYearFirstDay = new Date(Date.UTC(targetYear, 0, 1));
//     const nextYearFirstThursday = new Date(
//       Date.UTC(targetYear, 0, 1 + ((4 - nextYearFirstDay.getUTCDay() + 7) % 7))
//     );
//     if (targetDate >= nextYearFirstThursday)
//       return getISOWeekDate(1, targetYear);
//   }

//   const monday = new Date(targetDate);
//   monday.setUTCDate(
//     targetDate.getUTCDate() - ((targetDate.getUTCDay() + 6) % 7)
//   );

//   return monday;
// };

export const getWeekYear = (date: Date): {week: number; year: number} => {
  const momentDate = moment.utc([
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
  ]);
  return {
    week: momentDate.isoWeek(),
    year: momentDate.isoWeekYear(),
  };
};

export const getISOWeekDate = ({week, year}: WeekYear): Date => {
  if (week < 1 || week > 53) throw new Error('Week number must be between 1 and 53');

  const date = moment()
    .year(year)
    .isoWeek(week)
    .startOf('isoWeek');

  return date.toDate();
};

export const getWeekNumber = (date: Date): number => {
  return getWeekYear(date).week;
};

export const getWeekNumber2 = (date: Date): number => {
  const target = new Date(date.valueOf());
  const dayNr = (date.getDay() + 6) % 7;
  target.setDate(target.getDate() - dayNr + 3);

  const yearStart = new Date(target.getFullYear(), 0, 1);

  const firstThursday = new Date(yearStart);
  firstThursday.setDate(1 + ((4 - yearStart.getDay() + 7) % 7));

  if (target < firstThursday)
    return getWeekNumber2(new Date(target.getFullYear() - 1, 11, 31));

  const weekNum =
    1 +
    Math.floor(
      (target.getTime() - firstThursday.getTime()) / (7 * 24 * 60 * 60 * 1000),
    );

  if (weekNum > 52 && new Date(target.getFullYear(), 11, 31).getDay() < 4)
    return 1;

  return weekNum;
};

export const addTime = (timeA: string, timeB: string) => {
  const hrsA = parseInt(timeA.split(':')[0]);
  const minA = parseInt(timeA.split(':')[1]);
  const hrsB = parseInt(timeB.split(':')[0]);
  const minB = parseInt(timeB.split(':')[1]);

  if (minA + minB >= 60) {
    const newHr = Math.floor((minA + minB) / 60);
    const newMin = ((minA + minB) % 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const sumHr = (hrsA + hrsB + newHr).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });

    return `${sumHr} : ${newMin}`;
  }

  const newMin = (minA + minB).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const sumHr = (hrsA + hrsB).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  return `${sumHr}:${newMin}`;
};

export const chooseRandomColor = () => {
  const colorArray = [
    'bg-red-400',
    'bg-blue-200',
    'bg-green-400',
    'bg-yellow-300',
    'bg-purple-300',
  ];
  const randomSelector = Math.floor(Math.random() * 5);
  return colorArray[randomSelector];
};

export const validateEmail = (email: string) => {
  if (email === '') return false;
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const validatePassword = (password: string) => {
  if (password === '') return false;
  return true;
};

export const validateText = (text: string) => {
  if (text === '') return false;
  return true;
};

export const validateDate = (date: Date) => {
  if (date === null) return false;
  return true;
};

export const validateDuration = (duration: string) => {
  if (
    /(?:(?:(\d{1,2})h\s?(\d{1,2})m)|((\d{1,2}):(\d{1,2}))|((\d+)(\.\d+)?h)|((\d+)(\.\d+)?))/.test(
      duration,
    )
  )
    return true;
  return false;
};

export const validateDurationTime = (s: string) => {
  if (!s || s === '') return false;
  const pattern =
    /(?:(?:(\d{1,2})h\s?(\d{1,2})m)|((\d{1,2}):(\d{1,2}))|((\d+)(\.\d+)?h)|((\d+)(\.\d+)?))/;

  const match = s.match(pattern);

  if (!match) return false;

  let hours;
  let minutes;

  if (match[1] && match[2]) {
    hours = parseInt(match[1], 10);
    minutes = parseInt(match[2], 10);
  } else if (match[4] && match[5]) {
    hours = parseInt(match[4], 10);
    minutes = parseInt(match[5], 10);
  } else if (match[7]) {
    hours = parseInt(match[7], 10);
    minutes = match[8] ? Math.round(parseFloat(match[8]) * 60) : 0;
  } else if (match[10]) {
    hours = parseInt(match[10], 10);
    minutes = match[11] ? Math.round(parseFloat(match[11]) * 60) : 0;
  }

  // Check if hours and minutes are valid
  if (hours < 0 || minutes < 0 || minutes > 59) return false;

  // Check if the duration is in a 15-minute interval
  if (minutes % 15 !== 0) return false;

  return true;
};

export const durationTo24HourClock = (s: string) => {
  if (!s || s === '') return false;
  const pattern =
    /(?:(?:(\d{1,2})h\s?(\d{1,2})m)|((\d{1,2}):(\d{1,2}))|((\d+)(\.\d+)?h)|((\d+)(\.\d+)?))/;

  const match = s.match(pattern);

  if (!match) return false;

  let hours;
  let minutes;

  if (match[1] && match[2]) {
    hours = parseInt(match[1], 10);
    minutes = parseInt(match[2], 10);
  } else if (match[4] && match[5]) {
    hours = parseInt(match[4], 10);
    minutes = parseInt(match[5], 10);
  } else if (match[7]) {
    hours = parseInt(match[7], 10);
    minutes = match[8] ? Math.round(parseFloat(match[8]) * 60) : 0;
  } else if (match[10]) {
    hours = parseInt(match[10], 10);
    minutes = match[11] ? Math.round(parseFloat(match[11]) * 60) : 0;
  }

  // Check if hours and minutes are valid
  if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) return false;

  // Check if the duration is in a 15-minute interval
  if (minutes % 15 !== 0) return false;

  // Convert hours and minutes to 24-hour clock format
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
};

export const getMinutesfromHours = (s: string) => {
  const hours = parseInt(s.split(':')[0]);
  const minutes = parseInt(s.split(':')[1]);
  return minutes + 60 * hours;
};

export const setCurrentTime = (date: Date) => {
  const d = new Date();
  const result = date.setHours(
    d.getHours(),
    d.getMinutes(),
    d.getSeconds(),
    d.getMilliseconds(),
  );
  return new Date(result);
};

export const logTypeName = (logType: string) => {
  // "PROJ" "HOLY" "ADMIN" "ICT" "QUALITY" "MANAGEMENT" "MARKETING" "MEETINGS" "PERSONNEL" "TRAINING" "ATV" "SPECIAL_LEAVE" "NATIONAL_HOLIDAY" "PARENTAL_LEAVE" "WTV" "SICK"
  switch (logType) {
  case 'HOLY':
    return 'Holiday';
    break;
  case 'ADMIN':
    return 'Administration';
    break;
  case 'ICT':
    return 'ICT';
    break;
  case 'QUALITY':
    return 'Quality';
    break;
  case 'MANAGEMENT':
    return 'Management';
    break;
  case 'MARKETING':
    return 'Marketing';
    break;
  case 'MEETINGS':
    return 'Meetings';
    break;
  case 'PERSONNEL':
    return 'Personnel';
    break;
  case 'TRAINING':
    return 'Training';
    break;
  case 'ATV':
    return 'ATV';
    break;
  case 'SPECIAL_LEAVE':
    return 'Special leave';
    break;
  case 'NATIONAL_HOLIDAY':
    return 'National holiday';
    break;
  case 'PARENTAL_LEAVE':
    return 'Parental leave';
    break;
  case 'WTV':
    return 'WTV';
    break;
  case 'SICK':
    return 'Sick';
    break;
  default:
    return logType;
    break;
  }
};

export const checkWeekState = (state: string) => {
  switch (state) {
  case 'APPROVED':
    return 'bg-green-400';
    break;
  case 'SUBMITTED':
    return 'bg-yellow-500';
    break;
  case 'OPEN':
    return 'bg-blue-500';
    break;
  case 'REJECTED':
    return 'bg-red-500';
    break;
  default:
    return '';
    break;
  }
};

export const checkGeneralWeekState = (state: string) => {
  switch (state) {
  case 'APPROVED':
    return 'bg-green-600';
    break;
  case 'SUBMITTED':
    return 'bg-yellow-500';
    break;
  case 'OPEN':
    return 'bg-blue-500';
    break;
  case 'REJECTED':
    return 'bg-red-500';
    break;
  default:
    return '';
    break;
  }
};

export const checkRoleNames = (state: string) => {
  switch (state) {
  case 'MAN':
    return 'Manager';
    break;
  case 'USER':
    return 'User';
    break;
  case 'ADMIN':
    return 'Administrator';
    break;
  default:
    return 'User';
    break;
  }
};

export const checkFunctionName = (state: string) => {
  switch (state) {
  case 'MANAGEMENT_TEAM':
    return 'Management team';
    break;
  case 'CONSULTANT':
    return 'Consultant';
    break;
  case 'CONSULTANT_S_I':
    return 'Consultant S&I';
    break;
  case 'FACULTY':
    return 'Faculty';
    break;
  case 'MANAGING_BOARD':
    return 'Managing board';
    break;
  case 'MARKETING':
    return 'Marketing';
    break;
  case 'PROJECT_CENTER':
    return 'Project center';
    break;
  case 'PROJECT_MANAGER':
    return 'Project manager';
    break;
  case 'SALES':
    return 'Sales';
    break;
  default:
    return 'Unknown';
    break;
  }
};

export const parseFilter = (queryOptions: any) => {
  const params = queryOptions.filterModel?.items?.map((item: any) => {
    return item.value ?
      `${item.columnField}${checkFilter(item.operatorValue)}=${item.value}&` :
      '';
  });
  return params;
};

export const checkFilter = (val: string) => {
  let lookup;
  switch (val) {
  case '=':
    lookup = '';
    break;
  case 'starts with':
    lookup = '__startswith';
    break;
  case 'contains':
    lookup = '';
    break;
  case 'notContains':
    lookup = '__contains!';
    break;
  case 'ends with':
    lookup = '__endswith';
    break;
  case '!=':
    lookup = '!';
    break;
  case 'dateIs':
    lookup = '__date';
    break;
  case 'dateIsNot':
    lookup = '__date!';
    break;
  case 'onOrBefore':
    lookup = '_range_before';
    break;
  case 'onOrAfter':
    lookup = '_range_after';
    break;
  case '<':
    lookup = '__lt';
    break;
  case '>':
    lookup = '__gt';
    break;
  case '<=>':
    lookup = '__lte';
    break;
  case '>=':
    lookup = '__gte';
    break;
  default:
    lookup = '';
    break;
  }
  return lookup;
};

export const isObjEmpty = (obj: any) => {
  return Object.entries(obj).length === 0;
};
