import {SelectedTime} from '@/@types/time';
import {getWeekYear} from '@/components/utils/helper';
import React, {ReactNode, useState} from 'react';

export const SelectedTimeContext = React.createContext(null);

type SelectedTimeProps = {
  children?: ReactNode;
};

export const SelectedTimeProvider = ({children}: SelectedTimeProps) => {
  const currentDate = new Date();
  const [selectedTime, setSelectedTime] = useState<SelectedTime>({
    month: currentDate,
    week: getWeekYear(currentDate).week,
    weekYear: getWeekYear(currentDate),
  });

  return (
    <SelectedTimeContext.Provider value={{selectedTime, setSelectedTime}}>
      {children}
    </SelectedTimeContext.Provider>
  );
};
